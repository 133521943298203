@use '../../styles/partials/mixins';

.register-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixins.tablet-size {
    align-items: flex-start;
  }
}

.register-logo {
  @include mixins.logo;

  @include mixins.tablet-size {
    margin-left: 2rem;
  }
}

.register-main {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;

  @include mixins.tablet-size {
    flex-direction: row;
    justify-content: space-evenly;
  }

  &__title {
    text-align: center;
  }

  &__container {
    @include mixins.card;
    width: 80%;
    display: flex;
    flex-direction: column;

    @include mixins.tablet-size {
      width: 70%;
    }

    &__image {
      height: 15rem;
      border-radius: 0.5rem;
      margin: 1rem;
      object-fit: cover;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.register-subtext {
  width: 100%;
  text-align: center;
}
.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__input {
    @include mixins.input;
    margin: 1rem 0;

    &:focus {
      outline: none;
      border-bottom: rgba(137, 43, 226, 0.322) solid 0.05rem;
    }

    &--error {
      border-bottom: red solid 0.05rem;
    }
  }
}

.register-button {
  @include mixins.button;
  margin-bottom: 2rem;
  width: 12rem;

  &:hover {
    background-color: blueviolet;
    color: whitesmoke;
  }
}

.register-footer {
  background-color: #4adede;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mixins.tablet-size {
    justify-content: flex-end;
  }

  &__logo {
    @include mixins.logo;

    @include mixins.tablet-size {
      margin-right: 2rem;
    }
  }
}

.tech {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixins.tablet-size {
    width: 100%;
  }
}

.icons-box {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;

  @include mixins.tablet-size {
    width: 70%;
  }

  &__element {
    width: 3rem;
    height: 3rem;
    margin: 0.75rem;
    object-fit: contain;
  }
}
