@use '../../styles/partials/mixins';

.dashboard-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixins.tablet-size {
    align-items: flex-start;
  }
}

.dashboard-logo {
  @include mixins.logo;

  @include mixins.tablet-size {
    margin-left: 2rem;
  }
}

.dashboard-main {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
  }

  &__container {
    @include mixins.card;
    width: 90%;
    display: flex;
    flex-direction: column;

    @include mixins.tablet-size {
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 4rem;
    }

    &__image {
      height: 9rem;
      border-radius: 0.5rem;
      margin: 1rem;
      object-fit: cover;

      @include mixins.tablet-size {
        height: 18rem;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__box {
      @include mixins.card;
      width: 10rem;
      box-shadow: none;
      border: solid #4adede 0.025rem;
    }
  }
}

.dashboard-main-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-subtext {
  width: 100%;
  text-align: center;
}

.link {
  @include mixins.link;
}

.dashboard-footer {
  background-color: #4adede;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mixins.tablet-size {
    justify-content: flex-end;
  }

  &__logo {
    @include mixins.logo;

    @include mixins.tablet-size {
      margin-right: 2rem;
    }
  }
}
