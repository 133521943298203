@use '../../styles/partials/fonts';
@use '../../styles/partials/mixins';

html {
  font-family: "Roboto";
}
body {
  margin: 0;
}

input {
  border: none;
}

.header-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.infobar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
  border-bottom: rgba(137, 43, 226, 0.322) solid 0.05rem;

  &__button {
    @include mixins.button;
    width: 5rem;
    border-radius: 1rem;

    &:hover {
      background-color: blueviolet;
      color: whitesmoke;
    }
  }
}
.logo {
  @include mixins.logo;
}

.form {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  width: 100%;

  &__input {
    @include mixins.input;
  }
  &__button {
    @include mixins.button;
  }
}

.form-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.link {
  @include mixins.link;

  @include mixins.tablet-size {
    margin-top: 1.5rem;
  }

  &:hover {
    background-color: white;
    color: blueviolet;
    border: rgba(137, 43, 226, 0.322) solid 0.05rem;
    box-shadow: 0.15rem 0.15rem 0.5rem rgba(137, 43, 226, 0.322);
    height: 1.05rem;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 0 auto;

  @include mixins.tablet-size {
    flex-direction: row;
  }

  &__image {
    width: 60%;

    @include mixins.tablet-size {
      margin-right: 2rem;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
}

.main {
  margin: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    @include mixins.card;
    width: 70vw;

    @include mixins.tablet-size {
      width: 80vw;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: row;
    }

    &--off-position {
      @include mixins.tablet-size {
        align-self: flex-end;
      }
      align-self: center;
      box-shadow: -0.25rem 0.25rem 0.75rem rgba(137, 43, 226, 0.322);
    }

    &__image {
      width: 60%;
      margin: 0.5rem 0;

      @include mixins.tablet-size {
        width: 40%;
        margin-right: 2rem;
        margin-left: 1rem;
        height: 20rem;
      }
    }

    &__elements {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;

      @include mixins.tablet-size {
        width: 80%;
      }
    }
  }
}

.footer {
  background-color: #4adede;
  height: 10rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__logo {
    @include mixins.logo;
    margin-right: 1rem;
  }

  &__link {
    display: flex;
    text-decoration: none;
    color: black;
    font-size: 1rem;
    margin-right: 4rem;
    margin-left: 2rem;
    padding: 0.2rem;

    &:hover {
      border-bottom: rgba(137, 43, 226, 0.322) solid 0.25rem;
    }

    @include mixins.tablet-size {
      margin-right: 8rem;
      font-size: 1.5rem;
    }
  }
}
