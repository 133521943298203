@mixin button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: none;
  background-color: #4adede;
  height: 1.25rem;
  font-size: 1rem;
  padding: 1rem;
  margin-top: 0.5rem;
  width: 80%;
  cursor: pointer;
  font-family: "Roboto";
}

@mixin card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid rgba(137, 43, 226, 0.322) 0.025rem;
  box-shadow: 0.25rem 0.25rem 0.75rem rgba(137, 43, 226, 0.322);
  margin: 2rem 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

@mixin input {
  border: none;
  border-bottom: rgba(137, 43, 226, 0.322) solid 0.025rem;
  background-color: white;
  margin-top: 1rem;
  font-size: 1rem;
  text-indent: 0.25rem;
}

@mixin link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: blueviolet;
  color: white;
  padding: 0.5rem;
  border-radius: 1rem;
  // width: 80%;
  width: 12rem;
  padding: 0.5rem 0rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

@mixin logo {
  display: flex;
  width: 8rem;
  height: 5.5rem;
  margin: 1rem 0rem;
  object-fit: contain;
}

// Media queries

@mixin tablet-size {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop-size {
  @media screen and (min-width: 1279px) {
    @content;
  }
}
